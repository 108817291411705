.bannerContainer {
  width: 100%;
  height: 25vh;
  margin-bottom: 5em;
}

.bannerContainer .banner {
  width: 100%;
  height: 100%;
}

.aboutUsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  width: 100%;
}

.aboutUsContainer .title {
  /* border: 2px solid red; */
  flex-basis: 100%;
  text-align: center;
  font-size: 4em;
  /* flex-basis: 100%; */
  height: fit-content;
  font-family: "Gulzar", serif;
  font-weight: 400;
  font-style: normal;
  color: red;
}

.pictureContainer .item1 {
  height: 350px;
}

.textContainer {
  /* border: 2px solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
}

.textContainer .text {
  color: white;
  font-size: 2em;
  line-height: 2.5em;
  flex-basis: 45%;
  padding-right: 5em;
  font-family: "Amiri", serif;
  font-weight: 400;
  font-style: normal;
}

.textContainer2,
.textContainer3 {
  /* border: 2px solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
}

.textContainer2 .text,
.textContainer3 .text {
  color: white;
  font-size: 2em;
  line-height: 2.5em;
  flex-basis: 45%;
  padding-right: 5em;
  font-family: "Amiri", serif;
  font-weight: 400;
  font-style: normal;
}

.textContainer2 {
  margin-top: -3%;
}

.textContainer3 {
  margin-top: -5%;
}

.container {
  /* height: 100vh; */
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 200px;
}

.container .title {
  color: red;
  text-align: center;
  font-size: 4em;
  flex-basis: 100%;
  height: fit-content;
  font-family: "Gulzar", serif;
  font-weight: 400;
  font-style: normal;
}

.container .pictureContainer {
  flex-basis: 20%;
  width: 100%;
  height: fit-content;
}

.container .textContainer {
  color: red;
  font-size: 2em;
  line-height: 2.5em;
  flex-basis: 45%;
  padding-right: 5em;
  font-family: "Amiri", serif;
  font-weight: 400;
  font-style: normal;
  border: 2px solid red;
}

.pictureContainer2 {
  /* border: 2px solid red; */
  display: flex;
  align-content: center;
  justify-content: center;
}

.pictureContainer2 .item2 {
  height: 400px;
  align-content: center;
  /* text-align: center; */
}

.container .pictureContainer .socialContainer {
  border: 2px solid white;
  margin-top: 8em;
  border-radius: 2em;
  box-shadow: 1px 1px 1px 2px red;
}

.socialText {
  color: white;
  font-size: 2em;
  text-align: center;
}

.list li {
  display: inline;
  margin-right: 1.5em;
}

.text2 {
  color: white;
  font-size: 2em;
  line-height: 2.5em;
  /* flex-basis: 45%; */
  padding-right: 5em;
  font-family: "Amiri", serif;
  font-weight: 400;
  font-style: normal;
  /* border: 2px solid red; */
  text-align: center;
  width: fit-content;
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .aboutUsContainer .title {
    font-size: 3.5em;
  }
  .pictureContainer .item1 {
    height: 300px;
  }

  .textContainer .text {
    color: white;
    font-size: 2em;
    line-height: 1.8em;
    flex-basis: 100%;
    padding-right: 2em;
    padding-left: 2em;
    font-family: "Amiri", serif;
    font-weight: 400;
    font-style: normal;
  }

  .pictureContainer2 .item2 {
    height: 300px;
    align-content: center;
    /* text-align: center; */
  }

  .container .pictureContainer .socialContainer {
    border: 2px solid white;
    margin-top: 8em;
    border-radius: 2em;
    box-shadow: 1px 1px 1px 2px red;
  }

  .textContainer2 .text,
  .textContainer3 .text,
  .text2 {
    color: white;
    font-size: 2em;
    line-height: 1.8em;
    flex-basis: 45%;
    padding-right: 5em;
    font-family: "Amiri", serif;
    font-weight: 400;
    font-style: normal;
  }

  .text2 {
    padding-left: 80px;
  }
}

@media only screen and (max-width: 550px) {
  .aboutUsContainer .title {
    font-size: 2em;
  }
  .pictureContainer .item1 {
    height: 200px;
  }

  .textContainer .text {
    color: white;
    font-size: 1em;
    line-height: 1.8em;
    flex-basis: 100%;
    padding-right: 2em;
    padding-left: 2em;
    font-family: "Amiri", serif;
    font-weight: 400;
    font-style: normal;
  }

  .pictureContainer2 .item2 {
    height: 300px;
    align-content: center;
    /* text-align: center; */
  }

  .container .pictureContainer .socialContainer {
    border: 2px solid white;
    margin-top: 8em;
    border-radius: 2em;
    box-shadow: 1px 1px 1px 2px red;
  }

  .textContainer2 .text,
  .textContainer3 .text,
  .text2 {
    color: white;
    font-size: 1em;
    line-height: 1.8em;
    flex-basis: 45%;
    padding-right: 5em;
    font-family: "Amiri", serif;
    font-weight: 400;
    font-style: normal;
  }

  .text2 {
    padding-left: 80px;
  }
}
