body {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.main {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
}

.main video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.content {
  color: red;
  font-size: 6em;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  width: 100vw;
  max-width: 100%;
  text-align: center;
}

.content .text-ar {
  font-family: "Gulzar", serif;
  font-weight: 400;
  font-style: normal;
}

.content .text-en {
  font-family: "Edu AU VIC WA NT Hand", cursive;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.overlay {
  position: absolute;
  /* z-index: 1; */
  border: 2px solid;
  width: 99.8%;
  height: 100%;
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 1000px) {
  .content {
    font-size: 4em;
  }
}

@media only screen and (max-width: 550px) {
  .content {
    font-size: 2.5em;
  }
}
