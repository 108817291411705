.footerContainer {
  /* border: 2px solid red; */
  height: 20vh;
  width: 100vw;
  max-width: 100%;
  background-color: #0e1111;
}

.footerContainer .innerContainer {
  width: 60%;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.footerContainer .innerContainer .logoContainer {
  flex-basis: 50%;
  /* border: 2px solid white; */
  height: 80%;
  display: flex;
  justify-content: center;
  padding-top: 1em;
}

.footerContainer .innerContainer .linksContainer {
  flex-basis: 35%;
  height: 80%;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  padding-top: 1em;
}

.footerContainer .innerContainer .connectContainer {
  flex-basis: 22%;
  height: 80%;
  /* border: 2px solid red; */
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.footerContainer .innerContainer .logoContainer .image {
  width: 30%;
  height: 80%;
}

.footerContainer .innerContainer .linksContainer .title {
  color: white;
  font-size: 1.3em;
  flex-basis: 100%;
  height: 20%;
  /* border: 2px solid red; */
  align-content: center;
  text-align: center;
}

.footerContainer .innerContainer .linksContainer .list {
  /* border: 2px solid white; */
  height: 70%;
}
.footerContainer .innerContainer .linksContainer .link-inactive {
  color: white;
  /* border: 2px solid red; */
  /* list-style: none; */
  font-size: 1.1em;

  display: block;
  text-decoration: none;
  margin-bottom: 1em;
}

.footerContainer .innerContainer .linksContainer .active {
  font-size: 1.2em;
  display: block;
  color: red;
  margin-bottom: 1em;
}
/* 
.footerContainer .innerContainer .connectContainer .title {
  color: white;
}
.footerContainer .innerContainer .connectContainer .title {
  color: white;
  font-size: 1.5em;
  flex-basis: 100%;
  height: 20%;
  align-content: center;
  text-align: center;
} */

/* .footerContainer .innerContainer .connectContainer .logo {
  flex-basis: 40%;
  height: 40%;
  align-content: center;
  border: 2px solid red;
}

.footerContainer .innerContainer .connectContainer .logo .youtubeLogo {
  width: 80%;
  height: 100%;
  border: 2px solid green;
}

.footerContainer .innerContainer .connectContainer .logo .instagramLogo {
  width: 30%;
  height: 50%;
}

.footerContainer .innerContainer .connectContainer .logo .facebookLogo {
  width: 30%;
  height: 50%;
}

.footerContainer .innerContainer .connectContainer .logo .tiktokLogo {
  width: 35%;
  height: 80%;
} */

.nextMoveContainer {
  height: 20%;
  color: white;
  font-size: 1.5em;
  align-content: center;
  text-align: center;
}

.nextMoveContainer a {
  color: red;
}

@media only screen and (max-width: 768px) {
  .footerContainer .innerContainer {
    width: 100%;
    margin: auto;
    /* border: 2px solid white; */
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
  }
  .footerContainer .innerContainer .linksContainer {
    /* border: 2px solid red; */
    flex-basis: 40%;
    height: 80%;
    margin: auto;
  }
  .footerContainer .innerContainer .linksContainer .title {
    font-size: 1em;
    /* height: 20%; */
  }
  .footerContainer .innerContainer .linksContainer .active {
    font-size: 0.7em;
    display: block;
    color: red;
    margin-bottom: 0.5em;
  }
  .footerContainer .innerContainer .linksContainer .link-inactive {
    font-size: 0.7em;
    margin-bottom: 1em;
  }
  .footerContainer .innerContainer .logoContainer {
    flex-basis: 40%;
    /* border: 2px solid white; */
    height: 80%;
    display: flex;
    justify-content: center;
    /* padding-top: 1em; */
    padding-left: 2em;
  }
  .footerContainer .innerContainer .logoContainer .image {
    width: 80%;
    height: 80%;
  }
  .nextMoveContainer {
    width: 100%;
    height: 10%;
    color: white;
    font-size: 1em;
    align-content: center;
    text-align: center;
    /* border: 2px solid green; */
  }
}
