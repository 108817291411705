.outerVideoContainer .title {
  font-size: 4em;
  color: red;
  font-family: "Gulzar", serif;
  font-weight: 400;
  font-style: normal;
  height: 20%;
  flex-basis: 100%;
  text-align: center;
  margin-top: 100px;
}

.sliderContainer {
  /* border: 1px solid red; */
  width: 80%;
  margin: auto;
}
.sliderContainer2 {
  text-align: center;
  width: 80%;
  margin: auto;
}
.videoContainer {
  /* padding-left: 2em;
  padding-right: 5em;
  margin-right: 2em;
  margin-left: 2em; */
}
.videoContainer iframe {
  height: 400px;
  width: 300px;
  /* padding-left: 2em; */
}

@media only screen and (max-width: 550px) {
  .outerVideoContainer .title p {
    font-size: 0.7em;
  }

  .videoContainer iframe {
    height: 200px;
    width: 250px;
    padding-left: 2em;
  }
}

@media only screen and (max-width: 768px) {
  .outerVideoContainer .title {
    font-size: 3em;
  }

  .videoContainer iframe {
    height: 200px;
    width: 250px;
    padding-left: 2em;
  }
}
