.navBarOuterContainer {
  display: flex;
  flex-direction: row;
  width: 100vw;
  max-width: 100%;
  height: 10vh;
  justify-content: center;
  flex-wrap: nowrap;
  font-family: "Quicksand";
  position: relative;
}

.navBarOuterContainer .logo {
  flex-basis: 20%;
  /* border: 2px solid blue; */
  text-align: center;
  z-index: 2;
}

.navBarOuterContainer .navBarInnerContainer {
  /* border: 2px solid red; */
  flex-basis: 80%;
  height: 100%;
}

.navBarOuterContainer .navBar {
  display: inline;
  text-align: center;
  display: flex;
  justify-content: center;
  color: white;
  padding-left: 20%;
  font-size: 1.5em;
}

.navBarOuterContainer .navBar .link-inactive {
  list-style-type: none;
  display: inline-block;
  margin-left: 1.2em;
  color: white;
  text-decoration: none;
}

.navBarOuterContainer .navBar .link-inactive:hover {
  color: red;
}

.navBarOuterContainer .navBar .active {
  list-style-type: none;
  display: inline-block;
  margin-left: 1.2em;
  color: red;
}
.buttonContainer {
  flex-basis: 8%;
  min-width: 6em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 0.5%;
  height: fit-content;
}

.buttonContainer .engTag,
.buttonContainer .arTag {
  color: white;
}
.buttonContainer .engTag {
  padding-right: 5px;
}

.buttonContainer .arTag {
  padding-left: 5px;
}
.buttonContainer .innerContainer {
  flex-basis: 50%;
  text-align: center;
}
.toggle-btn {
  background-color: #b7b9ba;
  border: 1px solid #aaa;
  border-radius: 99px;
  width: 48px;
  height: 28px;
  transition: background-color 0.1s ease, border-color 0.2s ease;
  cursor: pointer;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.748);
  position: relative;
}

.toggle-btn .thumb {
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 99px;
  transform: translateX(0);
  transition: left 0.15s ease;
  position: absolute;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
}

.toggle-btn-toggled {
  background-color: red;
  border-radius: 99px;
  width: 50px;
  height: 28px;
  border: 1px solid #aaa;
  border-radius: 99px;
  width: 48px;
  height: 28px;
  transition: background-color 0.1s ease, border-color 0.2s ease;
  cursor: pointer;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.748);
  position: relative;
}

.toggled-btn:hover {
  border-color: #6f6f6f;
}

.toggle-btn-toggled .thumb {
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 99px;
  transition: left 0.15s ease;
  top: 50%;
  transform: translateY(-50%);
  left: 25px;
  position: absolute;
}

@media only screen and (max-width: 550px) {
  .navBarOuterContainer .navBar {
    display: none;
  }
  .navBarOuterContainer .logo {
    text-align: center;
    position: absolute;
  }

  .navBarOuterContainer .logo img {
    height: 30%;
  }

  .hamburgerMenu {
    flex-basis: 10%;
    color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    padding-bottom: 9%;
    gap: 10px;
    top: 0;
    left: 0;
    position: absolute;
    padding-left: 10px;
    padding-right: 20px;
    padding-top: 25px;
    z-index: 11;
    font-size: 25px;

    /* border: 2px solid red; */
  }

  .burger-bar-unclicked {
    flex-basis: 100%;
    height: 2%;
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
  }

  .burger-bar-clicked {
    flex-basis: 100%;
    height: 2%;
  }

  .menu-visible {
    height: 50vh;
    width: 50%;
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    transform: translateY(-1.75rem);
    transition: transform 250ms;
    /* pointer-events: all; */
    z-index: 10;
    pointer-events: all;
  }
  .menu-hidden {
    display: none;
  }

  .hamburgerNavBar {
    height: 60%;
    color: white;
    list-style: none;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px;
    z-index: 10;
    pointer-events: all;
    display: inline-block;
    /* position: relative; */
    /* position: absolute;
    z-index: 2; */
  }
  .hamburgerNavBar .link-inactive {
    color: white;
    font-size: 17px;
    font-family: "Inconsolata", monospace;
    /* width: fit-content; */
    height: fit-content;
    /* margin-right: 60px; */
    margin-bottom: 30px;
    margin-top: 20px;
    display: block;
    text-decoration: none;
    pointer-events: all;
    /* text-align: center; */
    float: center;
    /* position: absolute; */
    z-index: 10;
    /* position: absolute;
    z-index: 2; */
    /* pointer-events: none; */
  }

  .hamburgerNavBar .active {
    color: red;
  }

  .burger-bar-unclicked {
    border: 2px solid white;
  }

  .burger-bar-clicked {
    border: 2px solid red;
  }
  .burger-bar-clicked:nth-child(1) {
    transform: rotate(45deg) translate(-0.2em, 0.35em);
    transition: ease-out 0.5s;
  }

  .burger-bar-clicked:nth-child(2) {
    transform: scale(0.1);
    transition: ease-out 0.5s;
  }

  .burger-bar-clicked:nth-child(3) {
    transform: rotate(135deg) translate(-0.45em, 1em);
    transition: ease-out 0.5s;
  }
  .burger-bar-unclicked {
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
  }

  .buttonContainer {
    min-width: 7em;
    right: 0;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 4.5%;
    height: fit-content;
  }

  .buttonContainer .engTag {
    padding-right: 5px;
  }

  .buttonContainer .arTag {
    padding-left: 5px;
  }
  .buttonContainer .innerContainer {
    flex-basis: 50%;
    text-align: center;
  }
  .toggle-btn {
    background-color: #b7b9ba;
    border: 1px solid #aaa;
    border-radius: 99px;
    width: 48px;
    height: 28px;
    transition: background-color 0.1s ease, border-color 0.2s ease;
    cursor: pointer;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.748);
    position: relative;
  }

  .toggle-btn .thumb {
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 99px;
    transform: translateX(0);
    transition: left 0.15s ease;
    position: absolute;
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
  }

  .toggle-btn-toggled {
    background-color: red;
    border-radius: 99px;
    width: 50px;
    height: 28px;
    border: 1px solid #aaa;
    border-radius: 99px;
    width: 48px;
    height: 28px;
    transition: background-color 0.1s ease, border-color 0.2s ease;
    cursor: pointer;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.748);
    position: relative;
  }

  .toggled-btn:hover {
    border-color: #6f6f6f;
  }

  .toggle-btn-toggled .thumb {
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 99px;
    transition: left 0.15s ease;
    top: 50%;
    transform: translateY(-50%);
    left: 25px;
    position: absolute;
  }
}

@media only screen and (min-width: 551px) {
  .hamburgerNavBar {
    display: none;
  }
}
