.socialMediaContainer .title {
  color: red;
  font-size: 3.5em;
  text-align: center;
  font-family: "Gulzar", serif;
  font-weight: 400;
  font-style: normal;
  margin-top: 100px;
}

.socialMediaContainer .outerContainerTest {
  height: 10vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}
.socialMediaContainer .outerContainerTest2 {
  height: 10vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  margin-bottom: 10vh;
}

.Test {
  flex-basis: 10%;
}

.Test .pic {
  height: 5em;
  transition: transform 1000ms;
}

.Test .pic-ig {
  height: 4em;
  transition: transform 1000ms;
}

.Test .pic-fb {
  height: 4em;
  transition: transform 1000ms;
}

.Test .pic:hover,
.Test .pic-ig:hover,
.Test .pic-fb:hover {
  transform: translateY(-1.75rem);
  transition: transform 250ms;
}

@media only screen and (max-width: 550px) {
  .socialMediaContainer .title {
    font-size: 2em !important;
  }
  .Test {
    padding-left: 30px;
  }

  .Test .pic {
    height: 3em;
    transition: transform 1000ms;
  }

  .Test .pic-ig {
    height: 3em;
    transition: transform 1000ms;
  }

  .Test .pic-fb {
    height: 3em;
    transition: transform 1000ms;
  }
}

@media only screen and (max-width: 768px) {
  .socialMediaContainer .title {
    font-size: 3em;
  }
  .Test {
    padding-left: 30px;
  }

  .Test .pic {
    height: 4em;
    transition: transform 1000ms;
  }

  .Test .pic-ig {
    height: 3em;
    transition: transform 1000ms;
  }

  .Test .pic-fb {
    height: 3em;
    transition: transform 1000ms;
  }
}
