body {
  margin: 0;
  padding: 0;
}
.contactUsContainer {
  /* border: 2px solid red; */
  height: 88vh;
  width: 100vw;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 4em;
  margin-top: 3em;
}

.contactUsContainer .formContainer {
  /* border: 2px solid green; */
  flex-basis: 70%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.contactUsContainer .formContainer .innerContainer {
  flex-basis: 50%;

  background-color: #0e1111;
  border-radius: 2rem;
  height: 98%;
}

.contactUsContainer .formContainer .innerContainer .title {
  color: red;
  flex-basis: 100%;
  /* border: 2px solid white; */
  height: 15%;
  /* font-size: 65px; */
  font-size: 3em;
  text-align: center;
  font-family: "Bebas Neue", sans-serif;
}

.contactUsContainer .formContainer .innerContainer .form {
  flex-basis: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.contactUsContainer .formContainer .innerContainer .form .firstNameContainer
  /* .contactUsContainer .formContainer .innerContainer .form .lastNameContainer,
  .contactUsContainer .formContainer .innerContainer .form .phoneNumberContainer,
  .contactUsContainer .formContainer .innerContainer .form .emailContainer,
  .contactUsContainer .formContainer .innerContainer .form .messageContainer { */ {
  /* border: 2px solid red; */
  height: 15%;
  flex-basis: 90%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-end;
  /* border: 2px solid white; */
}

.contactUsContainer
    .formContainer
    .innerContainer
    .form
    .firstNameContainer
    .firstName
  /* .contactUsContainer
    .formContainer
    .innerContainer
    .form
    .lastNameContainer
    .lastName,
  .contactUsContainer
    .formContainer
    .innerContainer
    .form
    .phoneNumberContainer
    .phoneNumber,
  .contactUsContainer
    .formContainer
    .innerContainer
    .form
    .emailContainer
    .email { */ {
  color: white;
  flex-basis: 100%;
  height: 40px;
  /* width: 100px; */
  text-align: center;
  border-radius: 2rem;
  background-color: black;
  font-size: 18px;
  margin-left: 10px;
  /* align-content: center; */
}

.contactUsContainer .formContainer .innerContainer .form .lastNameContainer {
  /* border: 2px solid red; */
  height: 10%;
  flex-basis: 90%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}

.contactUsContainer
  .formContainer
  .innerContainer
  .form
  .lastNameContainer
  .lastName {
  color: white;
  flex-basis: 100%;
  height: 40px;

  text-align: center;
  border-radius: 2rem;
  background-color: black;
  font-size: 18px;
  margin-left: 10px;
}

.contactUsContainer .formContainer .innerContainer .form .phoneNumberContainer {
  /* border: 2px solid red; */
  height: 10%;
  flex-basis: 90%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}

.contactUsContainer
  .formContainer
  .innerContainer
  .form
  .phoneNumberContainer
  .phoneNumber {
  color: white;
  flex-basis: 100%;
  height: 40px;
  text-align: center;
  border-radius: 2rem;
  background-color: black;
  font-size: 18px;
  margin-left: 10px;
}

.contactUsContainer .formContainer .innerContainer .form .emailContainer {
  /* border: 2px solid red; */
  height: 10%;
  flex-basis: 90%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}

.contactUsContainer
  .formContainer
  .innerContainer
  .form
  .emailContainer
  .email {
  color: white;
  flex-basis: 100%;
  height: 40px;
  text-align: center;
  border-radius: 2rem;
  background-color: black;
  font-size: 18px;
  margin-left: 10px;
}

.contactUsContainer .formContainer .innerContainer .form .messageContainer {
  height: 25%;
  flex-basis: 90%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}

.contactUsContainer
  .formContainer
  .innerContainer
  .form
  .messageContainer
  .message {
  color: white;
  flex-basis: 100%;
  /* height: 100px; */
  height: 100%;
  text-align: center;
  border-radius: 2rem;
  background-color: black;
  font-size: 18px;
  margin-left: 10px;
  align-content: center;
}

.contactUsContainer
  .formContainer
  .innerContainer
  .form
  .submitButtonContainer {
  height: 10%;
  flex-basis: 90%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}
.contactUsContainer
  .formContainer
  .innerContainer
  .form
  .submitButtonContainer
  .submitButton {
  height: fit-content;
  border-radius: 2rem;
  color: black;
  width: 100px;
  font-size: 22px;
  font-family: "Bebas Neue", sans-serif;
  color: black;
  background-color: white;
}

.contactUsContainer
  .formContainer
  .innerContainer
  .form
  .submitButtonContainer
  .submitButton:hover {
  background-color: red;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .contactUsContainer .logo {
    flex-basis: 35%;
  }

  .contactUsContainer .formContainer {
    flex-basis: 63%;
  }

  .contactUsContainer .formContainer .innerContainer {
    flex-basis: 80%;
    background-color: #1a171c;
    border-radius: 2rem;
    height: 80%;
  }

  .contactUsContainer .formContainer .innerContainer .title {
    font-size: 50px;
  }

  .contactUsContainer
    .formContainer
    .innerContainer
    .form
    .submitButtonContainer
    .submitButton {
    height: 45px;
    width: fit-content;
  }
}

@media only screen and (max-width: 550px) {
  .contactUsContainer .logo {
    flex-basis: 0%;
  }

  .contactUsContainer .formContainer {
    flex-basis: 95%;
  }

  .contactUsContainer .formContainer .innerContainer {
    flex-basis: 80%;
    background-color: #1a171c;
    border-radius: 2rem;
    height: 80%;
  }

  .contactUsContainer .formContainer .innerContainer .title {
    font-size: 40px;
  }

  .contactUsContainer
    .formContainer
    .innerContainer
    .form
    .submitButtonContainer
    .submitButton {
    height: 40px;
    width: 80px;
    font-size: 18px;

    /* width: fit-content; */
  }
}

@media only screen and (max-width: 1280px) and (min-width: 769px) {
  .contactUsContainer .logo {
    flex-basis: 30%;
  }

  .contactUsContainer .formContainer {
    flex-basis: 65%;
  }

  .contactUsContainer .formContainer .innerContainer {
    flex-basis: 80%;
    background-color: #1a171c;
    border-radius: 2rem;
    height: 80%;
  }

  .contactUsContainer .formContainer .innerContainer .title {
    font-size: 60px;
  }

  .contactUsContainer
    .formContainer
    .innerContainer
    .form
    .submitButtonContainer
    .submitButton {
    height: 45px;
    width: fit-content;
  }
}
